import {
  DigitalCampaignCategoryHeroBannerTextAlignmentEnum,
  type DigitalCampaignCategoryHeroBanner,
  type DigitalCampaignHeroBanner,
  type Store,
} from "@xxl/content-api";
import type { NextPage } from "next";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import CampaignHeroBanner from "react-app/src/components/CampaignHeroBanner/CampaignHeroBanner";
import { GridSection } from "react-app/src/components/GridSection/GridSection";
import { SectionWrapper } from "react-app/src/components/GridSection/GridSection.styled";
import type { ProductBannerPricing } from "react-app/src/components/PriceDisplays/price-display-helper";
import {
  convertToBannerPricing,
  fetchPriceDisplays,
} from "react-app/src/components/PriceDisplays/price-display-helper";
import {
  parseSearchParams,
  pushOrUpdatePageUrl,
  SearchContext,
} from "react-app/src/components/Search";
import { useSharedData } from "react-app/src/contexts/SharedData";
import type { DefaultLayoutData, XXLAppData } from "../../global";

import { useSessionSource } from "@/react-app/contexts/Session";
import { updateCategoryPath } from "@/react-components/CategorySelector/Campaign/campaign-category-selector-helper";
import { useSelectedColumnsNumber } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber";
import { getStoreIds } from "@/react-components/Search/SearchContentHelper";
import { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import { useStores } from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect/useStores";
import {
  getPreferredStoresCookie,
  getXXLCookie,
  setPreferredStoresCookie,
} from "@/react-utils/Cookie";
import { useSymplifyWithSanity } from "@/react-utils/Symplify/hooks";
import { isSearchPage } from "@/react-utils/xxl-page-type";
import { getBannersFromGridSection } from "@/utils/campaign-page-helper";
import { useMediaQuery } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import { Breadcrumbs } from "react-app/src/components/Breadcrumbs";
import { CampaignCategorySelector } from "react-app/src/components/CategorySelector/Campaign";
import { ErrorPage } from "react-app/src/components/ErrorPage/ErrorPage";
import { FilterBar } from "react-app/src/components/Filter/FilterBar/FilterBar";
import { ProductListWrapper } from "react-app/src/components/ProductList";
import { ProductList } from "react-app/src/components/ProductList/ProductList";
import { INITIAL_PAGE_NUMBER } from "react-app/src/components/Search/Constants";
import { dispatchXXLCookie } from "react-app/src/components/Search/CookieHelper";
import { EmptySearchResult } from "react-app/src/components/Search/EmptySearchResult";
import { FetchMoreDataButton } from "react-app/src/components/Search/FetchMoreDataButton";
import { callSearchAndUpdateState } from "react-app/src/components/Search/SearchFetchProductsHelper";
import { shouldRenderXXLLoader } from "react-app/src/components/Search/SearchHelper";
import {
  getInitialState,
  reducer,
} from "react-app/src/components/Search/SearchState";
import { useFetchProductListingSmallBannersData } from "react-app/src/components/Search/hooks/useFetchProductListingSmallBannersData";
import { useFilteredSmallBanners } from "react-app/src/components/Search/hooks/useFilteredSmallBanners";
import { useLoginCallback } from "react-app/src/components/Search/hooks/useLoginCallback";
import { useScrollRestoration } from "react-app/src/components/Search/hooks/useScrollRestoration";
import { useSearchApi } from "react-app/src/components/Search/hooks/useSearchApi";
import { useSortsForLoginStatus } from "react-app/src/components/Search/hooks/useSortsForLoginStatus";
import { AvailabilityBar } from "react-app/src/components/Sort/AvailabilitySelector/AvailabilityBar";
import { XXLLoader } from "react-app/src/components/XXLLoader";
import { useApiClients } from "react-app/src/contexts/ApiClients";
import { useTracking } from "react-app/src/contexts/Tracking";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { SANITY_TESTS_MAP } from "react-app/src/utils/Symplify/constants";
import {
  addXXLEventListener,
  removeXXLEventListener,
  XXL_CATEGORY_PATH_CHANGED,
} from "react-app/src/utils/xxl-event";
import { mobileMediaQuery } from "react-app/src/utils/xxl-screen";
import { useRelinkValue } from "react-relink";
import { BreadcrumbsContainer } from "../../components/ProductDetailsPage/ProductDetailsPage.styles";
import { NewsletterLink } from "../../components/digitalCampaign/NewsletterLink";
import {
  mapGridSectionFromContentModule,
  type GridSection as GridSectionType,
} from "../../utils/apis/content-digital-campaign-page-api";
import { withPageData } from "../../utils/common-page-data/common-page-data";
import { SYMPLIFY_ORIGINAL_KEY } from "../../utils/constants";
import { getNewCampaignPageProps } from "./getServerSideProps";
import type { DigitalCampaignPageProps } from "./types";

const pickCampaignHeroBanner = (
  categoryPath: string,
  landingPageBanner: DigitalCampaignHeroBanner,
  categoryBanners: DigitalCampaignCategoryHeroBanner[]
) => {
  if (isEmpty(categoryPath)) {
    return landingPageBanner;
  }
  const categoryBanner = categoryBanners.find(
    ({ categoryCode }) => categoryCode === categoryPath
  );
  if (categoryBanner === undefined) {
    return landingPageBanner;
  }
  return categoryBanner;
};

const DigitalCampaignPage: NextPage<
  DigitalCampaignPageProps & XXLAppData & DefaultLayoutData
> = ({
  campaignHubUrl,
  campaignId,
  campaignPageResult,
  categoryBanners,
  environmentData: { frontEndServerUrl },
  gridSections,
  landingPageBannerContent,
  landingPageSelectorLabel,
  pageBaseUrl,
  productCodes,
  requestTimeout,
  requestUrl,
  siteId,
  toggle_cacheable_campaign_search_query: cacheableCampaignSearchQuery,
  toggle_force_solr_as_provider_on_pages: forceSolrAsProvider,
  toggle_plp_one_card_content_component,
  productListingSmallBannersIds,
  ipaperLink,
  isBot,
  additionalCampaignIds,
  abTestsPage,
}) => {
  const props = {
    cacheableCampaignSearchQuery,
    campaignPageResultV2: campaignPageResult,
    campaignId,
    categoryInfo: null,
    forceSolrAsProvider,
    isInitialRenderFromServer: false,
    page: INITIAL_PAGE_NUMBER,
    pageBaseUrl,
    relatedGuides: [],
    relativePageUrl: requestUrl ?? null,
    searchData: null,
    siteId,
    tabsResponse: null,
    togglePlpOneCardContentComponent: toggle_plp_one_card_content_component,
    longTailFacets: [],
    longTailPattern: null,
    isBot,
    additionalCampaignIds,
    buyingGuideData: null,
  };
  const [{ current: state }, dispatch] = useReducer(
    reducer,
    getInitialState(props)
  );
  const { t } = useTranslations();
  const trackers = useTracking();
  const {
    campaignData,
    categoryPath,
    initialSorts: defaultSorts,
    page,
    productListingSmallBanner,
    provider,
    searchData,
    selectedCategoryCode,
    selectedFilters,
    selectedSort,
    showAdditionalView = false,
    userGroups,
  } = state;
  const { count: numberOfProducts = 0, items = [] } = searchData?.results ?? {};
  const {
    categories = [],
    categoryContentsIds,
    level1Categories,
  } = campaignData ?? {};
  const breadcrumbsData = [
    { name: t("breadcrumbs.home"), url: "/" },
    { name: t("breadcrumbs.campaignhub"), url: campaignHubUrl },
    { name: "Mega sale", url: "" },
  ];
  const frontendBase =
    typeof window !== "undefined"
      ? window.location.origin
      : state.pageBaseUrl ?? "";

  const {
    data: { cookieVersion, siteUid },
  } = useSharedData();
  const selectedColumnsNumber = useSelectedColumnsNumber({
    initialNumberOfColumnsPerDeviceOnPlp: null,
  });
  const searchApi = useSearchApi({ provider, requestTimeout });
  const { pricesApi } = useApiClients();
  const { storesData } = useStores();
  const [selectedHeroBanner, setSelectedHeroBanner] = useState<
    DigitalCampaignHeroBanner | DigitalCampaignCategoryHeroBanner | null
  >(null);
  const isMobile = useMediaQuery(mobileMediaQuery);
  const [selectedAvailability, setSelectedAvailability] = useState<
    AVAILABILITY[]
  >([]);
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>([]);
  const [landingPageBanner, setLandingPageBanner] =
    useState<DigitalCampaignHeroBanner>();
  const [smallListingBannerIds, setSmallListingBannerIds] = useState<string[]>(
    []
  );
  const [gridSectionsToDisplay, setGridSectionsToDisplay] = useState<
    GridSectionType[]
  >([]);
  const hasSymplify = useSymplifyWithSanity(
    "CAMPAIGN_PAGE_SANITY_TEST",
    campaignId
  );
  const isLoggedIn = useRelinkValue(useSessionSource);
  const [priceData, setPriceData] = useState<ProductBannerPricing[]>([]);

  useEffect(() => {
    if (hasSymplify === true && isNotNullOrUndefined(abTestsPage)) {
      const activeProjects = window.symplify.getActivatedProjects();
      const campaignPageProject = activeProjects.find(
        (item) =>
          item.projectName
            .toLowerCase()
            .includes(
              SANITY_TESTS_MAP.CAMPAIGN_PAGE_SANITY_TEST.PROJECT_NAME
            ) && item.projectName.includes(campaignId)
      );

      if (
        isNotNullOrUndefined(campaignPageProject) &&
        campaignPageProject.variationName !== SYMPLIFY_ORIGINAL_KEY
      ) {
        setLandingPageBanner(abTestsPage.landingPageBanner);
        if (isNotNullOrUndefined(abTestsPage.productListingSmallBannersIds)) {
          setSmallListingBannerIds(abTestsPage.productListingSmallBannersIds);
        }
        if (isNotNullOrUndefined(abTestsPage.landingPage?.contentModules)) {
          const mappedSections = abTestsPage.landingPage.contentModules.map(
            (item) => mapGridSectionFromContentModule(item)
          );
          setGridSectionsToDisplay(mappedSections);
        }
      }
    } else {
      setLandingPageBanner(landingPageBannerContent);
      setSmallListingBannerIds(productListingSmallBannersIds);
      setGridSectionsToDisplay(gridSections);
    }
  }, [
    abTestsPage,
    campaignId,
    gridSections,
    hasSymplify,
    landingPageBannerContent,
    productListingSmallBannersIds,
  ]);

  useEffect(() => {
    const { availability, ids } = getPreferredStoresCookie() ?? {};
    setSelectedAvailability(
      availability ?? [AVAILABILITY.ONLINE, AVAILABILITY.STORE]
    );
    setSelectedStoreIds(ids ?? []);
  }, []);

  useEffect(() => {
    const { userId } = state;
    if (userId === undefined || showAdditionalView) {
      return;
    }
    void callSearchAndUpdateState(
      dispatch,
      false,
      state,
      t,
      userId,
      searchApi,
      siteUid,
      trackers
    );
    // Using eslint-disable-next-line react-hooks/exhaustive-deps to ignore passing whole "state" object which would cause endless loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cookieVersion,
    t,
    searchApi,
    state.categoryPath,
    state.page,
    state.provider,
    state.selectedFilters,
    state.selectedSort,
    state.userGroups,
    state.userId,
  ]);

  useEffect(
    () =>
      pushOrUpdatePageUrl({
        page,
        selectedFilters,
        selectedSort,
      }),
    [page, selectedFilters, selectedSort]
  );
  const filteredSmallBanners = useFilteredSmallBanners({
    categoryProductListingSmallBanner: [],
    level1Categories,
    productListingSmallBanner,
    productListingSmallBannersIds: smallListingBannerIds,
    selectedCategoryCode,
  });
  useEffect(
    () => void dispatchXXLCookie(dispatch, cookieVersion),
    [cookieVersion]
  );
  useFetchProductListingSmallBannersData({
    categoryContentsIds,
    dispatch,
    frontendBase,
    level1Categories,
    productListingSmallBannersIds: smallListingBannerIds,
  });
  useLoginCallback({ dispatch });
  useScrollRestoration();
  useSortsForLoginStatus({ defaultSorts, dispatch, userGroups });
  const scrollToRef = useRef(null);

  const onCategoryPathChanged = useCallback(
    (searchString: string) => {
      const params = new URLSearchParams(searchString);
      const { categoryPath: categoryPathFromSearchParams } =
        parseSearchParams(params);

      if (isNotNullOrUndefined(landingPageBanner)) {
        const selectedBanner = pickCampaignHeroBanner(
          categoryPathFromSearchParams[0] ?? "",
          landingPageBanner,
          categoryBanners
        );
        setSelectedHeroBanner(selectedBanner);
      }
    },
    [categoryBanners, landingPageBanner]
  );

  useEffect(() => {
    onCategoryPathChanged(window.location.search);
  }, [onCategoryPathChanged]);

  useEffect(() => {
    if (isNotNullOrUndefined(landingPageSelectorLabel)) {
      dispatch({
        type: "SHOW_ADDITIONAL_VIEW",
        payload: true,
      });
    }
  }, [landingPageSelectorLabel, dispatch]);

  useEffect(() => {
    const banners = getBannersFromGridSection(gridSections);

    const uniqueCodesFromSection = [
      ...new Set(
        banners
          .flatMap(({ product }) => product?.productCode)
          .filter(isNotNullOrUndefined)
      ),
    ];

    try {
      void (async () => {
        const priceDisplays = await fetchPriceDisplays(
          [...productCodes, ...uniqueCodesFromSection],
          pricesApi
        );
        const prices = convertToBannerPricing(
          priceDisplays,
          t,
          isLoggedIn,
          siteUid,
          banners
        );

        setPriceData(prices);
      })();
    } catch (error) {
      console.error(error);
    }
  }, [
    gridSections,
    gridSectionsToDisplay,
    isLoggedIn,
    pricesApi,
    productCodes,
    siteUid,
    t,
  ]);

  useEffect(() => {
    addXXLEventListener(XXL_CATEGORY_PATH_CHANGED, () =>
      onCategoryPathChanged(window.location.search)
    );

    return () =>
      removeXXLEventListener(XXL_CATEGORY_PATH_CHANGED, () =>
        onCategoryPathChanged(window.location.search)
      );
  }, [onCategoryPathChanged]);

  const {
    heroTitle = "",
    textColor = "black",
    textAlignment = DigitalCampaignCategoryHeroBannerTextAlignmentEnum.center,
    imageOverlay = false,
    heroDescription,
    heroLabel,
    mobileImage,
    desktopImage,
    countdownTimerSettings,
    backgroundColor,
  } = selectedHeroBanner ?? {};

  const onAvailabilityChange = useCallback(
    async (stores: Store[], availability: AVAILABILITY[]) => {
      setPreferredStoresCookie({
        availability,
        ids: getStoreIds(stores),
      });

      setSelectedAvailability(availability);
      setSelectedStoreIds(
        stores
          .filter((store) => isNotNullOrUndefined(store.id))
          .map((store) => store.id ?? "")
      );

      try {
        const cookie = await getXXLCookie();
        if (cookie === null || cookie.loopId === undefined) {
          throw Error("Could not get loopId.");
        }
        state.userGroups = cookie.userGroups;
        void callSearchAndUpdateState(
          dispatch,
          isSearchPage(),
          state,
          t,
          cookie.loopId,
          searchApi,
          siteUid,
          trackers
        );
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, searchApi, siteUid, state, t, trackers]
  );

  const onCategoryChange = useCallback(
    (path: string[], isAdditionalViewSelected = false) => {
      updateCategoryPath(
        path,
        dispatch,
        isNotNullOrUndefined(landingPageSelectorLabel)
      );

      if (isAdditionalViewSelected) {
        dispatch({
          type: "SHOW_ADDITIONAL_VIEW",
          payload: true,
        });
      }
    },
    [landingPageSelectorLabel]
  );

  return (
    <>
      <div className="container">
        <BreadcrumbsContainer>
          <Breadcrumbs
            breadcrumbs={breadcrumbsData}
            homeUrl={frontEndServerUrl}
          />
        </BreadcrumbsContainer>
      </div>
      <CampaignHeroBanner
        title={heroTitle}
        textColor={textColor}
        textAlignment={textAlignment}
        imageOverlay={imageOverlay}
        label={heroLabel}
        description={heroDescription}
        mobileImage={mobileImage}
        desktopImage={desktopImage}
        countdownTimerSettings={countdownTimerSettings}
        backgroundColor={backgroundColor?.value}
        ipaperLink={ipaperLink}
      />
      <SectionWrapper className="component-standard-spacing">
        <SearchContext.Provider value={{ state, dispatch }}>
          {state.isFinalSearchFailed ? (
            <ErrorPage />
          ) : (
            <>
              <section className="container">
                {ipaperLink !== undefined && isMobile && (
                  <NewsletterLink url={ipaperLink} />
                )}
                <CampaignCategorySelector
                  additionalViewSelectorLabel={landingPageSelectorLabel}
                  categories={categories}
                  categoryPath={categoryPath}
                  onCategoryChange={onCategoryChange}
                  totalProductsCount={state.campaignTotalProductsCount}
                />
              </section>
              {showAdditionalView ? (
                gridSectionsToDisplay.map(
                  (
                    {
                      gridContainers,
                      sectionName,
                      visibleContainersCount,
                      buttonUrl,
                      buttonText,
                    },
                    index
                  ) =>
                    gridContainers !== undefined && (
                      <GridSection
                        key={index}
                        name={sectionName}
                        gridContainers={gridContainers}
                        visibleContainersCount={
                          visibleContainersCount ?? undefined
                        }
                        priceData={priceData}
                        buttonText={buttonText ?? undefined}
                        buttonUrl={buttonUrl ?? null}
                      />
                    )
                )
              ) : shouldRenderXXLLoader(state) ? (
                <div className="hero-component__wrap">
                  <XXLLoader />
                </div>
              ) : (
                <section className="category-list container">
                  <FilterBar
                    facets={null}
                    longTailFacets={null}
                    longTailPattern={null}
                    selectedColumnsNumber={selectedColumnsNumber}
                    selectedFilters={null}
                    selectedSort={null}
                    sortOptions={null}
                    totalHits={items.length}
                  />
                  <AvailabilityBar
                    numberOfProducts={numberOfProducts}
                    onChange={onAvailabilityChange}
                    selectedAvailability={selectedAvailability}
                    selectedColumnsNumber={selectedColumnsNumber}
                    selectedStoreIds={selectedStoreIds}
                    version={1}
                    storesData={storesData}
                  />
                  <ProductListWrapper>
                    <div>
                      {items.length > 0 ? (
                        <>
                          <ProductList
                            products={items}
                            hasAside={true}
                            ref={scrollToRef}
                            selectedColumnsNumber={selectedColumnsNumber}
                            smallBanners={filteredSmallBanners}
                          />
                          <FetchMoreDataButton
                            scrollToRef={scrollToRef.current}
                          />
                        </>
                      ) : (
                        <EmptySearchResult
                          heading={t("campaign.page.no.hits")}
                          text={t("campaign.page.choose.another.category")}
                        />
                      )}
                    </div>
                  </ProductListWrapper>
                </section>
              )}
              <section className="container">
                <CampaignCategorySelector
                  additionalViewSelectorLabel={landingPageSelectorLabel}
                  categories={categories}
                  categoryPath={categoryPath}
                  onCategoryChange={onCategoryChange}
                  totalProductsCount={state.campaignTotalProductsCount}
                />
              </section>
            </>
          )}
        </SearchContext.Provider>
      </SectionWrapper>
    </>
  );
};

export default DigitalCampaignPage;

export const getServerSideProps = withPageData(getNewCampaignPageProps);
