export const getPreviewableContent = async <T>({
  getDefaultContentFn,
  getPreviewContentFn,
  previewCondition,
}: {
  getDefaultContentFn: () => T;
  getPreviewContentFn: () => T;
  previewCondition: () => boolean;
}): Promise<T> => {
  if (previewCondition()) {
    return getPreviewContentFn();
  }
  return getDefaultContentFn();
};
